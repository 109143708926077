<script setup>
import { ref, useSlots, computed } from 'vue';
import OCardBody from 'o365.vue.components.CardBody.vue';
import OCardBodyCell from 'o365.vue.component.Card.BodyCell.vue';
import { defineEmits } from 'vue'
import { getDataObjectById } from 'o365.vue.ts'
import { getDataColumnsFromProp, getDataColumnsFromSlot } from 'o365.controls.CardView.Columns.ts';

const props = defineProps({
	item: Object,
	linkParams: Object,
	/**
	* Columns passed as an object instead of slots
	*/
	columns: Array,
	/**
	* HeaderColumns passed as an object instead of slots
	*/
	headerColumns: Array,
	showImages: {
		type: Boolean
	},
	imagesView: {
		type: String
	},
	imagesPrimKeyColumnName: {
		type: String
	},
	imageOnTop: {
		type: Boolean
	},
	noInfoAction: {
		type: Boolean
	},
	multiselect: {
		type: Boolean
	},
	isCurrent: {
		type: Boolean
	},
	imageUrlParameters: {
		type: String,
		default: "mwidth=100"//&mheight=200"
	}
});



const dataObject = computed(() => getDataObjectById(props.item?.dataObjectId, props.item?.appId));

const primKeyColumn = computed(() => {
	return props.imagesPrimKeyColumnName ?? 'primKey';;
});

const imagePrimKey = computed(() => {
	return props.item?.[primKeyColumn.value];
});

const imageUrl = computed(() => {
	if (imagePrimKey.value == null) {
		return null;
	}

	const fileName = props.item?.FileName ?? "file";

	return `/nt/api/file/view/${(props.imagesView ?? dataObject.value.viewName)}/${imagePrimKey.value}/${encodeURIComponent(fileName)}?scale=thumbnail&${props.imageUrlParameters}`;
});

const emit = defineEmits(['setCurrentItem'])

const slots = useSlots();
const dataColumns = ref(null);
const headerDataColumns = ref(null);

const hasActions = computed(() => {
	return !props.noInfoAction || slots.actions;
});


dataColumns.value = getDataColumnsFromProp(props, "columns") ?? getDataColumnsFromSlot(slots, "default", props);
headerDataColumns.value = getDataColumnsFromProp(props, "headerColumns") ?? getDataColumnsFromSlot(slots, "header", props);

</script>

<template>
	<div class="card" :class="{ active: isCurrent }" @click="$emit('setCurrentItem', item)">

		<template v-if="showImages && imageOnTop">
			<div class="card-img-top card-image" :style="`background-image: url(${imageUrl})`" v-if="imagePrimKey">
			</div>
			<slot v-else name="fallbackImage">
			</slot>
		</template>


		<div class="card-header" v-if="headerDataColumns?.columns?.length">
			<div class="headers-container">
				<div v-for="(column, colIndex) in headerDataColumns.columns">
					<OCardBodyCell :col="column" :row="item" :colIndex="colIndex" :is-active-edit-cell="false" />
				</div>
			</div>
			<div class="actions" v-if="hasActions">
				<slot name="actions" :item="item"></slot>
				<a href="#" v-if="!noInfoAction" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-info"
					class="bi bi-info-circle"></a>
			</div>
		</div>
		<OLink v-if="linkParams" :modelValue="item" :cellRendererParams="linkParams" class="card-body position-relative">
			<OCardBody :dataColumns="dataColumns" :item="item" :showImages="showImages && !imageOnTop" :imageUrl="imageUrl">
				<template #fallbackImage>
					<slot name="fallbackImage"></slot>
				</template>
			</OCardBody>

			<div class="actions-body" v-if="hasActions && !headerDataColumns?.columns?.length">
				<slot name="actions" :item="item"></slot>
				<a href="#" v-if="!noInfoAction" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-info"
					class="bi bi-info-circle"></a>
			</div>
		</OLink>
		<div v-else class="card-body position-relative d-flex">
			<input type="checkbox" v-if="multiselect" v-model="item.isSelected" :readonly="item.lockSelection" class="mr-2">
			<div :class="{ 'ms-2': multiselect }" class="flex-grow-1">
				<OCardBody :dataColumns="dataColumns" :item="item" :showImages="showImages && !imageOnTop"
					:imageUrl="imageUrl">
					<template #fallbackImage>
						<slot name="fallbackImage"></slot>
					</template>
				</OCardBody>
			</div>

			<div class="actions-body" v-if="hasActions && !headerDataColumns?.columns?.length">
				<slot name="actions" :item="item"></slot>
				<a href="#" v-if="!noInfoAction" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-info"
					class="bi bi-info-circle"></a>
			</div>
		</div>


		<div v-if="slots.footer" class="card-footer" slot="footer">
			<slot name="footer" :item="item"></slot>
		</div>
	</div>
</template>

<style scoped>
.card-image {
	width: 100%;
	aspect-ratio: 2 / 1;
	overflow: hidden;
	background-position: center;
	background-size: cover;
}

.card-header .card-text:first-child,
.offcanvas-header .card-text:first-child {
	font-size: 1.2em;
	font-weight: bold;
}

.card-body {
	padding: calc(var(--bs-card-spacer-y) - 3px) calc(var(--bs-card-spacer-x) - 3px)
}

.headers-container {
	min-width: 0;
	flex: 1;
}

.headers-container> :first-child {
	font-weight: bold;
	font-size: 24px;
}

.card-header {
	display: flex;
}

.card-header .actions {
	flex-grow: 0;
}

.actions,
.actions-body {
	display: flex;
	gap: 18px;
	padding-left: 8px;
	font-size: 24px;
}

.actions-body {
	position: absolute;
	right: 5px;
	top: 5px;
}

.card.selected {
	background-color: transparent;
}

.card.active {
	--bs-card-bg: var(--bs-primary-border-subtle);
}
</style>

